<template>
  <div >
    <div class="head-name-title" style="margin-left: 20px">采购审批</div>
    <div style="margin: 30px 0 30px 20px  ">
      <el-table
          :data="tableData"
          border
          style="width: 918px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="farmingName"
            label="农资名称"
        >
        </el-table-column>
        <el-table-column
            prop="num"
            label="数量">
        </el-table-column>
        <el-table-column
            prop="unit"
            label="单位">
        </el-table-column>
        <el-table-column
            prop="remark"
            label="备注">
        </el-table-column>
        <el-table-column
            prop="applyName"
            label="申请人">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="申请时间">
        </el-table-column>
        <el-table-column
            prop="statusName"
            label="申请状态">
        </el-table-column>
        <el-table-column v-if="this.$getPermission('/pc/farmingPurchase/actionApply')"
            prop="id"
            label="操作">
            <template slot-scope="scope"  >
              <a  style="cursor: pointer;color:#1A99F2 "
                  @click="handleEdit(scope.$index, scope.row)">通过
              </a>    <a  style="cursor: pointer;color:#1A99F2 "
                  @click="handleDelete(scope.$index, scope.row)">驳回
              </a>


            </template>
          </el-table-column>


      </el-table>
      <el-pagination
          style="margin-top: 20px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {

  inject:[
    'reload'
  ],
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      tableData: []
    }
  },

  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex','2')
  },
  methods: {
    getDataList() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
        "status": 0,
      }
      this.$api.post('farmingPurchase/getApproveList', params, res => {
        console.log(res, '采购审批数据返回')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    handleEdit(index, row) {  // 修改按钮
      console.log(index, row.id);
      let params = {
        "id": row.id,
        "status": 1,
        approveId:JSON.parse(localStorage.getItem('user_nongye')).userId,
      }
      this.$api.post('farmingPurchase/actionApply', params, res => {
        console.log(res, '审批采购申请返回')
        if (res.code === 0) {
          this.$message({
            showClose: true,
            duration:1500,
            message: res.msg,
            type: 'success',
            onClose: () => {
              this.$emit('changeSelectIndex','2')
              this.reload()
            }
          });
        }

      })
    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
      let params = {
        "id": row.id,
        "status": 4,
        approveId:JSON.parse(localStorage.getItem('user_nongye')).userId,

      }
      this.$api.post('farmingPurchase/actionApply', params, res => {
        console.log(res, '审批采购申请返回')
        if (res.code === 0) {
          this.$message({
            showClose: true,
            duration:1500,
            message: res.msg,
            type: 'success',
            onClose: () => {
              this.$emit('changeSelectIndex','2')
              this.reload()
            }
          });
        }

      })
    },
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
</style>
