<template>
  <div >
    <div class="head-name-title" style="margin-left: 20px">入库</div>
    <div style="margin:10px 0 30px 20px ">
      <el-button type="primary"  @click="dialogVisible = true" v-if="this.$getPermission('/pc/farmingStoreIn/saveIn')">新增入库</el-button>
      <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="30%"
      >
        <div style="padding:10px 30px 10px 30px ">
          <el-form  label-width="100px">
            <el-form-item label="农资分类">
              <el-select v-model="farmingCapitalTypeVal" placeholder="请选择"  @change="getSelectfarmingCapitalType">
                <el-option
                    v-for="item in farmingCapitalTypeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="农资名称">
              <el-select v-model="farmingCapitalVal" placeholder="请选择"  @change="getUnti" >
                <el-option
                    v-for="item in farmingCapital"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数量">
              <el-input v-model="num" style="width: 80%;"></el-input>
              <span style="width: 20%;display: inline-block"> {{ unit }}</span>
            </el-form-item>
            <el-form-item label="农资生产厂家">
              <el-input v-model="manufacturer"></el-input>
            </el-form-item>
            <el-form-item label="采购来源">
              <el-input v-model="source"></el-input>
            </el-form-item>
            <el-form-item style="text-align: left">
              <el-button class="dialog-button" @click="update">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <div >
      <div class="table-title-name"> 待确认</div>
      <el-table
          :data="tableData"
          border
          style="width: 918px;margin-left: 36px;margin-top: 10px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="farmingName"
            label="农资名称"
        >
        </el-table-column>
        <el-table-column
            prop="num"
            label="数量">
        </el-table-column>
        <el-table-column
            prop="procureTime"
            label="采购时间">
        </el-table-column>
        <el-table-column
            prop="manufacturer"
            label="农资生产厂家">
        </el-table-column>
        <el-table-column
            prop="source"
            label="采购来源">
        </el-table-column>
        <el-table-column
            prop="id"
            label="操作">
          <template slot-scope="scope"  >
            <a  style="cursor: pointer;color:#1A99F2 "
                @click="handleEdit(scope.$index, scope.row)">确认入库
            </a>

            <el-popconfirm style="margin-left: 10px"
                           title="确定删除吗？"
                           @confirm="handleDelete(scope.$index, scope.row)"
            >
              <a   style="cursor: pointer;color:#1A99F2 "
                  type="danger"
                  slot="reference"
              >删除
              </a>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-left: 30px;margin-top: 10px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>


    <div  style="margin-bottom: 30px" >
     <div class="table-title-name"> 近期入库记录</div>
      <el-table
          :data="tableData1"
          border
          style="width: 918px;margin-left: 36px;margin-top: 10px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="farmingName"
            label="农资名称"
        >
        </el-table-column>
        <el-table-column
            prop="num"
            label="数量">
        </el-table-column>
        <el-table-column
            prop="inTime"
            label="入库时间">
        </el-table-column>
        <el-table-column
            prop="manufacturer"
            label="农资生产厂家">
        </el-table-column>
        <el-table-column
            prop="source"
            label="采购来源">
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-left: 30px;margin-top: 10px"
          @size-change="sizeChangeHandle1"
          @current-change="currentChangeHandle1"
          :current-page="pageIndex1"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize1"
          :total="totalPage1"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  inject:[
    'reload'
  ],
  props: {
    farmingCapitalTypeList: {
      type: Array,
      defalut: []
    }
  },
  data() {
    return {
      num:'',
      value:'',
      pageIndex: 1,
      pageIndex1: 1,
      pageSize: 10,
      pageSize1: 10,
      totalPage: 0,
      totalPage1: 0,
      dialogVisible: false,
      tableData1: [],
      unit:'单位',
      farmingCapitalTypeVal:'',
      farmingCapitalVal:'',
      tableData: [],
      farmingCapital:[],
      manufacturer:'',
      source:''
    }
  },
  mounted() {
    this.getDataListEnd()
    this.getDataList()
    localStorage.setItem('selectIndex','4')
  },
  methods: {
    // 第一个下拉框选择
    getSelectfarmingCapitalType(res) {
      console.log(res,)
      let params = {
        "typeId":res
      }
      this.$api.post('farmingCapital/select', params, res => {
        console.log(res, '选中农资分类返回农资名字')
        if (res.code === 0) {
          this.farmingCapital = res.data
          this.farmingCapitalVal =''
        }

      })
    },
    getUnti(res){
      console.log(res,'sss')

      let idx = this.farmingCapital.findIndex((item)=> item.id == res)
      console.log(idx,'idx')
      this.unit = this.farmingCapital[idx].unit
    },
    getDataList() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
        "status": 1,
      }
      this.$api.post('farmingStoreIn/getInList', params, res => {
        console.log(res, '待入库数据返回')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },
    getDataListEnd() {
      let params = {
        "page": this.pageIndex1,
        "size": this.pageSize1,
        "status": 0,
      }
      this.$api.post('farmingStoreIn/getInList', params, res => {
        console.log(res, '入库数据返回11')
        if (res.code === 0) {
          this.tableData1 = res.data.records
          this.totalPage1 = res.data.total
        }

      })
    },
    update() {
      let params = {
        "farmingTypeId":this.farmingCapitalTypeVal,
        "farmingId": this.farmingCapitalVal,
        "num": this.num,
        "source": this.source,
        "manufacturer": this.manufacturer,
      }

      this.$api.post('farmingStoreIn/saveIn', params, res => {
        console.log(res, '入库申请数据返回')
        if (res.code === 0) {
          this.$message({
            showClose: true,
            duration:1500,
            message: res.msg,
            type: 'success',
            onClose:()=>{
              this.dialogVisible = false
              this.$emit('changeSelectIndex','4')
              this.reload()
            }

          });
        }

      })
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 每页数
    sizeChangeHandle1(val) {
      this.pageSize1 = val
      this.pageIndex1 = 1
      this.getDataListEnd()
    },
    // 当前页
    currentChangeHandle1(val) {
      this.pageIndex1 = val
      this.getDataListEnd()
    },
    handleEdit(index, row) {  // 修改按钮
      console.log(index, row.id);
      if(this.$getPermission('/pc/farmingStoreIn/confirmIn')){
        let params = {
          "id":row.id,
        }
        this.$api.post('farmingStoreIn/confirmIn', params, res => {
          console.log(res, '确认入库数据返回')
          if (res.code === 0) {
            this.$message({
              showClose: true,
              duration:1500,
              message: res.msg,
              type: 'success',
              onClose:()=>{
                this.dialogVisible = false
                this.$emit('changeSelectIndex','4')
                this.reload()
              }
            });
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }

    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
    },
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
.table-title-name{
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin: 10px 0 5px 36px;

}
.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}
.dialog-button {
  height: 50px;
  background: #009400;
  border-radius: 30px;
  width: 80%;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
