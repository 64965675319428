<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">采购申请</div>
    <div style="margin:20px 0 30px 20px ">
      <el-button type="primary" @click="dialogVisible = true"
                 v-if="this.$getPermission('/pc/farmingPurchase/saveApply')">新增采购申请
      </el-button>
      <el-dialog
          title="新增采购申请"
          :visible.sync="dialogVisible"
          width="30%"
      >
        <div style=" padding:10px 30px 10px 30px ">
          <div style="">
            <el-form label-width="80px">
              <el-form-item label="农资分类">
                <el-select v-model="farmingCapitalTypeVal" placeholder="请选择"
                           @change="getSelectfarmingCapitalType">
                  <el-option
                      v-for="item in farmingCapitalTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="农资名称">
                <el-select v-model="farmingCapitalVal" placeholder="请选择" @change="getUnti">
                  <el-option
                      v-for="item in farmingCapital"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="数量">
                <el-col>
                  <el-input v-model="num" style="width: 80%;"></el-input>
                  <span style="width: 20%;display: inline-block"> {{ unit }}</span>
                </el-col>


              </el-form-item>
              <el-form-item label="备注">
                <el-input v-model="remark"></el-input>
              </el-form-item>
              <el-form-item style="text-align: left">
                <el-button class="dialog-button" @click="update">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
    <div style="margin-left: 20px ">
      申请记录
      <el-table
          :data="tableData"
          border
          style="width: 918px;margin-top: 20px;margin-bottom: 30px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="farmingName"
            label="农资名称"
        >
        </el-table-column>
        <el-table-column
            prop="num"
            label="数量">
        </el-table-column>
        <el-table-column
            prop="unit"
            label="单位">
        </el-table-column>
        <el-table-column
            prop="remark"
            label="备注">
        </el-table-column>
        <el-table-column
            prop="applyName"
            label="申请人">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="添加时间">
        </el-table-column>
        <el-table-column
            prop="statusName"
            label="申请状态">
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-bottom: 20px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "purchase_request",
  props: {
    farmingCapitalTypeList: {
      type: Array,
      defalut: []
    }
  },
  inject: [
    'reload'
  ],
  data() {
    return {
      dialogVisible: false,
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      num: '',
      remark: '',
      value: '',
      unit: '单位',
      farmingCapitalTypeVal: '',
      farmingCapitalVal: '',
      tableData: [],
      farmingCapital: []
    }
  },
  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex', '1')
  },
  methods: {
    // 第一个下拉框选择
    getSelectfarmingCapitalType(res) {
      console.log(res,)
      let params = {
        "typeId": res
      }
      this.$api.post('farmingCapital/select', params, res => {
        console.log(res, '选中农资分类返回农资名字')
        if (res.code === 0) {
          this.farmingCapital = res.data
          this.farmingCapitalVal = ''
        }

      })
    },
    getUnti(res) {
      console.log(res, 'sss')

      let idx = this.farmingCapital.findIndex((item) => item.id == res)
      console.log(idx, 'idx')
      this.unit = this.farmingCapital[idx].unit
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },

    getDataList() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
      }
      this.$api.post('farmingPurchase/getApplyList', params, res => {
        console.log(res, '采购申请数据返回')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    update() {
      let params = {
        "farmingTypeId": this.farmingCapitalTypeVal,
        "farmingId": this.farmingCapitalVal,
        "num": this.num,
        "remark": this.remark,
      }

      this.$api.post('farmingPurchase/saveApply', params, res => {
        console.log(res, '采购申请数据返回')
        if (res.code === 0) {
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'success',
            onClose: () => {
              this.dialogVisible = false
              this.reload()
            }

          });
        }

      })
    }
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}

.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}

.dialog-button {
  height: 50px;
  background: #009400;
  border-radius: 30px;
  width: 80%;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
