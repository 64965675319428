<template>
  <div>
    <div class="body-box">
      <div style="width: 200px;float:left;">
        <el-menu
            :default-active="selectName"
            class="el-menu-vertical-demo"
            style="text-align: center"
            @select="handleOpen"
        >
          <el-menu-item index="1" v-if="this.$getPermission('/pc/farmingPurchase/getApplyList')">
            <span slot="title">采购申请</span>
          </el-menu-item>
          <el-menu-item index="2" v-if="this.$getPermission('/pc/farmingPurchase/getApproveList')">
            <span slot="title">采购审批</span>
          </el-menu-item>
          <el-menu-item index="3"  v-if="this.$getPermission('/pc/farmingPurchase/getPurchaseList')">
            <span slot="title">采购</span>
          </el-menu-item>
          <el-menu-item index="4"  v-if="this.$getPermission('/pc/farmingStoreIn/getInList')">
            <span slot="title">入库</span>
          </el-menu-item>
          <el-menu-item index="5"  v-if="this.$getPermission('/pc/farmingStoreOut/getOutList')">
            <span slot="title">出库</span>
          </el-menu-item>
          <el-menu-item index="6"  v-if="this.$getPermission('/pc/farmingStore/getList')">
            <span slot="title">实时库存</span>
          </el-menu-item>
          <el-menu-item index="7"  v-if="this.$getPermission('/pc/farmingStoreRecord/getList')">
            <span slot="title">入库出库记录</span>
          </el-menu-item>
<!--          <el-menu-item index="8">-->
<!--            <span slot="title">出库记录</span>-->
<!--          </el-menu-item>-->
        </el-menu>
      </div>
      <div class="agricul_right ib">
        <div v-if="selectName == 1">
          <purchase_request :farmingCapitalTypeList="farmingCapitalTypeList" ></purchase_request>
        </div>
        <div v-if="selectName == 2">
          <purchase_approval ></purchase_approval>
        </div>
        <div v-if="selectName == 3">
          <purchasing ></purchasing>
        </div>
        <div v-if="selectName == 4">
          <enter_warehousing :farmingCapitalTypeList="farmingCapitalTypeList" ></enter_warehousing>
        </div>
        <div v-if="selectName == 5">
          <out_warehouse :farmingCapitalTypeList="farmingCapitalTypeList" :userList="userList" ></out_warehouse>
        </div>
        <div v-if="selectName == 6">
          <realTime_warehouse></realTime_warehouse>
        </div>
        <div v-if="selectName == 7">
          <enter_warehouse_record :farmingCapitalTypeList="farmingCapitalTypeList"></enter_warehouse_record>
        </div>
<!--        <div v-if="selectName == 8">-->
<!--          <out_warehouse_record></out_warehouse_record>-->
<!--        </div>-->
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import purchase_request from '@/components/agricultural/purchase_request'
import purchase_approval from '@/components/agricultural/purchase_approval'
import purchasing from '@/components/agricultural/purchasing'
import enter_warehousing from '@/components/agricultural/enter_warehousing'
import out_warehouse from '@/components/agricultural/out_warehouse'
import enter_warehouse_record from '@/components/agricultural/enter_warehouse_record'
// import out_warehouse_record from '@/components/agricultural/out_warehouse_record'
import realTime_warehouse from '@/components/agricultural/realTime_warehouse'

export default {
  name: "agricultural",
  data() {
    return {
      selectName: localStorage.getItem('selectIndex'),
      farmingCapitalTypeList: [],
      userList: []
    }
  },
  components: {
    purchase_request,
    purchase_approval,
    purchasing,
    enter_warehousing,
    out_warehouse,
    enter_warehouse_record,
    realTime_warehouse,

  },//    out_warehouse_record,

  mounted() {
    this.$api.post('farmingCapitalType/select', {}, res => {
      console.log(res, '农资类型下拉')
      if (res.code === 0) {
        this.farmingCapitalTypeList = res.data
      }
    })
    this.$api.post('/user/select', {}, res => {
      console.log(res, '用户下拉')
      if (res.code === 0) {
        this.userList = res.data
      }
    })
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.selectName = key
    },
    changeSelectIndex(res){
      console.log(res,'物品出门')
      this.selectName = res
    }
  }
}
</script>

<style scoped>
.body-box {
  margin-top: 20px;
}

.ib {
  /*display: inline-block;*/
}

.agricul_right {
  margin-left: 10px;
  width: 990px;

  background: #FFFFFF;
  float: left;
}

.clear {
  clear: both
}
</style>
