<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">入库出库记录</div>
    <div style="margin:20px 0 30px 20px ">

      农资:
      <el-select v-model="farmingCapitalTypeVal" placeholder="请选择" style="width: 150px;margin-left: 10px"
                 @change="getSelectfarmingCapitalType">
        <el-option
            v-for="item in farmingCapitalTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select v-model="farmingCapitalVal" placeholder="请选择" style="width: 150px;margin-left: 20px">
        <el-option
            v-for="item in farmingCapital"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        >
        </el-option>
      </el-select>
      <el-radio-group v-model="radio" @change="radioChange" style="margin-left: 20px">
        <el-radio :label="0">入库</el-radio>
        <el-radio :label="1">出库</el-radio>
      </el-radio-group>
      <el-button type="success" icon="el-icon-search" style="margin-left: 30px;background: #009400" @click="serchButon">搜索</el-button>
    </div>
    <div style="margin: 30px 0 30px 20px  ">
      <el-table
          :data="tableData"
          border
          style="width: 950px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="farmingTypeName"
            label="农资品类"
        >
        </el-table-column>
        <el-table-column
            prop="farmingName"
            label="农资名称">
        </el-table-column>
        <el-table-column
            prop="num"
            label="数量">
        </el-table-column>
        <el-table-column
            prop="action"
            :formatter="formatRole"
            label="操作">
        </el-table-column>
        <el-table-column
            prop="manufacturer"
            label="农资生产厂家">
        </el-table-column>
        <el-table-column
            prop="source"
            label="采购来源">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="时间">
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-left: 30px;margin-top: 10px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    farmingCapitalTypeList: {
      type: Array,
      defalut: []
    }
  },
  data() {
    return {
      userId:JSON.parse(localStorage.getItem('user_nongye')).userId,
      radio:'',
      current: 0,
      tableData: [],
      farmingCapitalTypeVal: '',
      farmingCapitalVal: '',
      farmingCapital: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      value: ''
    }

  },
  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex','7')
  },
  methods: {
    formatRole: function( row) {
      return row.action == '0' ? "入库" : row.action == '1' ? "出库" : "";
    },
    serchButon(){
      let params = {
        "action": this.radio,
        "farmingId":this.farmingCapitalVal,
        "farmingTypeId": this.farmingCapitalTypeVal,
        "page": this.pageIndex,
        "size": this.pageSize,
      }
      this.$api.post('farmingStoreRecord/getList', params, res => {
        console.log(res, '出入库记录数据返回')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },
    getSelectfarmingCapitalType(res) {
      console.log(res,)
      let params = {
        "typeId": res
      }
      this.$api.post('farmingCapital/select', params, res => {
        console.log(res, '选中农资分类返回农资名字')
        if (res.code === 0) {
          this.farmingCapital = res.data
          this.farmingCapitalVal =''
        }
      })
    },
    getDataList() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
      }
      this.$api.post('farmingStoreRecord/getList', params, res => {
        console.log(res, '出入库记录数据返回')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      if(this.farmingCapitalTypeVal || this.farmingCapitalVal){
        this.serchButon()
      }else {
        this.getDataList()
      }
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      if (this.farmingCapitalTypeVal || this.farmingCapitalVal){
        this.serchButon()
      }else{
        this.getDataList()
      }
    },
    radioChange: function(evt) {
      console.log(evt)
      let params = {
        "action": this.radio,
        "farmingId":this.farmingCapitalVal,
        "farmingTypeId": this.farmingCapitalTypeVal,
        "page": this.pageIndex,
        "size": this.pageSize,
      }
      this.$api.post('farmingStoreRecord/getList', params, res => {
        console.log(res, '出入库记录数据返回')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}

</style>
