<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">采购</div>
    <div style="margin:20px 0 30px 20px ">
      <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="30%"
      >
        <div style="height: 300px;width: 400px;text-align: center;margin-left: 80px">
          <div class="dialog-title">采购结果</div>
          <el-form label-width="100px">

            <el-form-item label="农资生产厂家">
              <el-input v-model="manufacturer"></el-input>
            </el-form-item>
            <el-form-item label="采购来源">
              <el-input v-model="source"></el-input>
            </el-form-item>
          </el-form>
          <el-col>
            <el-button class="dialog-button" @click="update">提交</el-button>
          </el-col>
        </div>
      </el-dialog>
    </div>
    <div style="margin: 30px 0 30px 20px  ">
      <el-table
          :data="tableData"
          border
          style="width: 948px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="farmingName"
            label="农资名称"
        >
        </el-table-column>
        <el-table-column
            prop="num"
            label="数量">
        </el-table-column>
        <el-table-column
            prop="unit"
            label="单位">
        </el-table-column>
        <el-table-column
            prop="remark"
            label="备注">
        </el-table-column>
        <el-table-column
            prop="userName"
            label="申请人">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="申请时间">
        </el-table-column>
        <el-table-column
            prop="statusName"
            label="申请状态">
        </el-table-column>
        <el-table-column  v-if="this.$getPermission('/pc/farmingPurchase/getPurchaseList')"
            prop="id"
            label="操作">
          <template slot-scope="scope">
            <a style="cursor: pointer;color:#1A99F2 "
               @click="handleEdit(scope.$index, scope.row)">报告采购结果
            </a>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
          style="margin-top: 20px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {

  inject:[
    'reload'
  ],
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      manufacturer: '',
      source: '',
      selectId: ''
    }
  },
  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex','3')
  },
  methods: {
    update() {
      let params = {
        "id": this.selectId,
        "manufacturer": this.manufacturer,
        "source": this.source,
      }

      this.$api.post('farmingPurchase/actionPurchase', params, res => {
        if (res.code === 0) {
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'success',
            onClose: () => {
              this.reload()
              this.$emit('changeSelectIndex','3')
            }

          });
        }

      })
    },
    handleEdit(index, row) {
      this.selectId = row.id
      this.dialogVisible = true
    },
    getDataList() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
        "status": 2,
      }
      this.$api.post('farmingPurchase/getPurchaseList', params, res => {
        console.log(res, '采购审批数据返回')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },

    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },

  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}
.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 400px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
